import React from 'react';
import {Page} from '../components/Page';
import PageHeader from '../components/PageHeader';
import {Markdown} from '../components/Markdown';
import {Helmet} from 'react-helmet';

export default function Service({pageContext: {service}}) {
	return <Page>
		<Helmet>
{/*			<title>test</title>*/}
			<title>{service.titleSEO}</title>
			<meta name="description" content={service.descriptionSEO} />
{/*			<link rel="canonical" href={'https://www.icit.com.au/services/' + service.slug}  />*/}
		</Helmet>
		<PageHeader>
			{service.header}
		</PageHeader>
		<Markdown>
			{service.bodyTextMain}
		</Markdown>
		{!!service.image && <img src={service.image.localFile.publicURL} />}
		{!!service.bodyTextMore && <Markdown>
			{service.bodyTextMore}
		</Markdown>}
	</Page>;
}
